<div mat-dialog-content>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
            <div class="mb-4">
              <div class="fw-bold border-bottom pb-2 mb-3">Enter your password file</div>
              <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
                <div class="row mb-3">
                  <div class="col-lg-9">
                    <input formControlName="password" type="password" class="form-control">
                    <div *ngIf="passwordForm.controls['password']!.invalid && passwordForm.controls['password']!.touched" class="text-danger">
                      Password is required.
                    </div>
                  </div>
                </div>
              </form>
            </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link" (click)="onCancel()" >Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!passwordForm.valid" (click)="onSubmit()">Confirm</button>
      </div>
    </div>
  </div>
</div>
