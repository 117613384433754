<app-session-navigator (currentSessionEmitter)="handleCurrentSession($event)"></app-session-navigator>
<div class="d-flex justify-content-center pb-3 nb-present">
  <strong>{{groupAttendance().totalPersonPresent}} personne présentes</strong>
</div>
<div class="row">
  <div *ngFor="let group of groupAttendance().groupsAttendance" class="col-md-6">
    <div class="card">
      <div class="card-header">
        <div class="d-flex gap-2 align-items-center">
          <h5 class="mb-0">{{group.groupName}}</h5>
          <span>({{group.nbPerson}} pers.)</span>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Number</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody>
          <tr>
            <td>Student present</td>
            <td>
              {{group.nbPersonPresent}}/{{group.nbPerson}}
            </td>
            <td>{{group.presenceRate}}%</td>
          </tr>
          <tr>
            <td>Student late</td>
            <td>{{group.nbPersonLate}}/{{group.nbPersonPresent}}</td>
            <td>{{group.lateRate ?? 0}}%</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

