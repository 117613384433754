import { Injectable } from '@angular/core';
import {ConstantsService} from "../constants/constants.service";
import {map, Observable, tap} from "rxjs";
import {ConstantModel} from "../../models/constant.model";
import aes from 'crypto-js/aes'
import encUTF8 from 'crypto-js/enc-utf8'

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() { }

  decryptData<T>(encryptedData: string, pwd: string): T {
    const bytes  = aes.decrypt(encryptedData, pwd);
    const decryptedData = bytes.toString(encUTF8);
    return JSON.parse(decryptedData);
  }
}
