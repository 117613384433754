import {Component, computed, effect, EventEmitter, Input, OnInit, Output, signal} from '@angular/core';
import {SessionsService} from "../../services/sessions/sessions.service";
import {Session} from "../../models/session.model";
import {tap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-session-navigator',
  templateUrl: './session-navigator.component.html',
  styleUrls: ['./session-navigator.component.scss']
})
export class SessionNavigatorComponent implements OnInit {
  @Input() allowNavigationInFuture = false;
  @Output() currentSessionEmitter = new EventEmitter();

  indexSession = signal(-1);
  currentSession = computed(() =>  this.indexSession() >= 0 ? this.sessions[this.indexSession()] : null);
  selectedDate = computed(() => this.currentSession() ? this.currentSession()!.date : this.formattedDate(new Date()));
  disablePrevButton = computed(() => this.indexSession() === 0);
  disableNextButton = computed(() => this.indexSession() === this.sessions.length -1
                || ( !this.allowNavigationInFuture && new Date(this.sessions[this.indexSession()+1].date) > new Date()));

  private sessions: Session[] = [];

  constructor(private sessionService: SessionsService) {
    this.subscribeToSessions();
    this.effectOnCurrentSession();
  }

  ngOnInit(): void {
    this.sessionService.fetchSessions();
  }

  goToNextSession(): void {
    this.indexSession.update(index => index+1);
  }

  goToPrevSession(): void {
    this.indexSession.update(index => index-1);
  }

  private formattedDate(date: Date): string {
    return date.toISOString().split('T')[0]
  }

  private subscribeToSessions() {
    this.sessionService.sessions$.pipe(
      tap((sessions: Session[]) => {
        this.sessions = sessions;
        this.setIndexSession();
      }),
      takeUntilDestroyed(),
    ).subscribe();
  }

  private setIndexSession() {
    const index = this.sessions.findIndex((session) => session.date === this.selectedDate());
    if (index != -1) {
      this.indexSession.set(index);
    } else {
      const previousSession = this.findClosestPreviousSession();
      const previousIndex = this.sessions.findIndex(session => session.id === previousSession?.id);
      this.indexSession.set(previousIndex);
    }
  }

  private findClosestPreviousSession(): Session | null {
    const previousSessions = this.sessions.filter(session => new Date(session.date) < new Date());
    if (previousSessions.length === 0) {
      return null;
    }

    return previousSessions.reduce((closest, current) =>
      current.date > closest.date ? current : closest
    );
  }

  private effectOnCurrentSession() {
    effect(() => {
      if (this.currentSession()) {
        this.sessionService.fetchSessionById(this.currentSession()!.id);
        this.currentSessionEmitter.next(this.currentSession());
      }
    }, {allowSignalWrites: true})
  }

}
