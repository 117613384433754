import {Component, OnInit, Signal, signal} from '@angular/core';
import {Session} from "../../models/session.model";
import {GroupAttendanceService} from "../../services/group-attendance/group-attendance.service";
import {GroupAttendance, GroupsAttendance} from "../../models/group-attendance.model";

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent {

  currentSession = signal<Session | null>(null);
  groupAttendance: Signal<GroupsAttendance>;

  constructor(private groupAttendanceService: GroupAttendanceService) {
    this.groupAttendance = groupAttendanceService.groupAttendance.asReadonly();
  }

  handleCurrentSession(session: Session) {
    this.currentSession.set(session);
    this.groupAttendanceService.fetchGroupAttendanceBySession(session.id);
  }

}
