import {Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {GroupModel} from "../../models/group.model";
import {StrapiResponse} from "../../models/response.model";
import {map, Observable, of, ReplaySubject, shareReplay, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  private readonly GROUPS_URL = '/api/groups';

  private groupsCache$: Observable<GroupModel[]> | null = null;

  constructor(private http: HttpClient) {}

  fetchGroups(): Observable<GroupModel[]> {
    if (!this.groupsCache$) {
      this.groupsCache$ = this.http.get<StrapiResponse<GroupModel[]>>(this.GROUPS_URL).pipe(
        map(response => response.data),
        shareReplay(1)
      );
    }

    return this.groupsCache$;
  }
}
