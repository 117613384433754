import {Component, Inject, signal} from '@angular/core';
import {EncadrantAttendance} from "../../models/encadrant-attendance.model";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {GroupModel} from "../../models/group.model";
import {GroupsService} from "../../services/groups/groups.service";
import {EMPTY, filter, finalize, forkJoin, Observable, of, switchMap, tap} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {EncadrantsService} from "../../services/encadrants/encadrants.service";
import {GrpEncadrantsAttendance} from "../../models/grp-encadrants-attendance.model";
import {Replacement} from "../../models/replacement.model";

export interface EncadrantAttendanceData {
  encadrantAttendance: EncadrantAttendance,
  groupAttendance: GrpEncadrantsAttendance,
  sessionId: number,
  isEncadrant: boolean
}

@UntilDestroy()
@Component({
  selector: 'app-edit-encadrant-attendance-modal',
  templateUrl: './edit-encadrant-attendance-modal.component.html',
  styleUrls: ['./edit-encadrant-attendance-modal.component.scss']
})
export class EditEncadrantAttendanceModalComponent {
  encadrantForm: FormGroup;
  groupsAttendance$: Observable<GrpEncadrantsAttendance[]>;

  isLoading = signal(false);

  constructor(private dialogRef: MatDialogRef<EditEncadrantAttendanceModalComponent>,
              private fb: FormBuilder,
              private groupsService: GroupsService,
              private encadrantsService: EncadrantsService,
              @Inject(MAT_DIALOG_DATA) public data: EncadrantAttendanceData
  ) {
    this.encadrantForm = this.buildForm();
    this.groupsAttendance$ = encadrantsService.fetchOrInitializeEncadrantsForSession(data.sessionId);

    this.subscribePresent();
    this.subscribeLate();
  }


  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    const id = this.data.encadrantAttendance.id!;

    const body: Partial<EncadrantAttendance> = {
      present: this.encadrantForm.get('present')!.value,
      late: this.encadrantForm.get('late')!.value,
      isMain: this.encadrantForm.get('main')!.value,
      justification: this.encadrantForm.get('justification')!.value,
    }

    forkJoin([
      this.encadrantsService.saveEncadrantAttendance(id, body),
      this.saveNewGroup(),
    ]).pipe(
      tap({subscribe: () => this.isLoading.set(true)}),
      tap(() => this.dialogRef.close(true)),
      finalize(() => this.isLoading.set(false))
    ).subscribe();

    this.encadrantsService.saveEncadrantAttendance(id, body).subscribe();
  }

  private saveNewGroup(): Observable<GrpEncadrantsAttendance> {
    const oldGroup = this.data.groupAttendance.id!;
    const newGroup = this.encadrantForm.get('groupAttendance')!.value;
    const id = this.data.encadrantAttendance.id!;

    if(oldGroup === newGroup) {
      return of(this.data.groupAttendance);
    }

    return this.data.isEncadrant ? this.moveEncadrantToNewGroup(oldGroup, id, newGroup) :
        this.moveToReplacementToNewGroup(oldGroup,id, newGroup);
  }

  private moveToReplacementToNewGroup(oldGroup: number, id: number, newGroup: number) {
    return this.encadrantsService.removeReplacementFromGrpAttandance(oldGroup, id).pipe(
      switchMap(() => this.encadrantsService.addReplacementtoGrpAttandance(newGroup, id))
    );
  }

  private moveEncadrantToNewGroup(oldGroup: number, id: number, newGroup:number) {
    return this.encadrantsService.removeEncadrantFromGrpAttandance(oldGroup, id).pipe(
      switchMap(() => this.encadrantsService.addEncadranttoGrpAttandance(newGroup, id))
    );
  }

  private buildForm(): FormGroup {
    return this.fb.group({
      main: this.fb.control(this.data.encadrantAttendance.isMain, Validators.required),
      present: this.fb.control(this.data.encadrantAttendance.present, Validators.required),
      late: this.fb.control(this.data.encadrantAttendance.late, Validators.required),
      justification: this.fb.control(this.data.encadrantAttendance.justification),
      groupAttendance: this.fb.control(this.data.groupAttendance.id!),
      name: this.fb.control(this.data?.encadrantAttendance?.replacement?.name, this.data.isEncadrant ? [] : [Validators.required])
    });
  }

  private subscribePresent() {
    this.encadrantForm.get('present')!.valueChanges
      .pipe(
        filter(value => !value),
        tap(() => this.encadrantForm.get('late')?.setValue(false, {emitEvent: false})),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private subscribeLate() {
    this.encadrantForm.get('late')!.valueChanges
      .pipe(
        filter(Boolean),
        tap(() => this.encadrantForm.get('present')?.setValue(true, {emitEvent: false})),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
