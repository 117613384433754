import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AttendanceComponent} from "./components/attendance/attendance.component";
import {LoginComponent} from "./components/login/login.component";
import {ResetPasswordComponent} from "./components/reset-password/reset-password.component";
import {AuthGuard} from "./guards/auth-guard.guard";
import {GroupsComponent} from "./components/groups/groups.component";
import {AdminGuard} from "./guards/admin.guard";
import {EncadrantsAttendanceComponent} from "./components/encadrants-attendance/encadrants-attendance.component";
import {UpdatePasswordComponent} from "./components/update-password/update-password.component";

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {path: 'reset-password', component: ResetPasswordComponent},
  {path: 'update-password', component: UpdatePasswordComponent},
  {path: 'attendance',  canActivate: [AuthGuard], component: AttendanceComponent},
  {path: 'groups',  canActivate: [AuthGuard, AdminGuard], component: GroupsComponent},
  {path: 'encadrants-attendance',  canActivate: [AuthGuard, AdminGuard], component: EncadrantsAttendanceComponent},
  { path: '', redirectTo: '/attendance', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
