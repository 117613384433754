<div class="d-flex justify-content-center align-items-center">
  <form [formGroup]="form" (ngSubmit)="updatePassword()">

    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <h5 class="mb-0">Update your password</h5>
        </div>

        <div *ngIf="errorMessage()">
          <div class="alert alert-danger" role="alert">
            {{errorMessage()}}
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Current password</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input type="password" formControlName="password" required class="form-control">
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted"></i>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">New password</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input type="password" formControlName="newPassword" required class="form-control">
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted"></i>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Confirm password</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input type="password" formControlName="confirmPassword" required class="form-control">
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted"></i>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <button type="submit" [disabled]="form.invalid" class="btn btn-primary w-100">Update password</button>
        </div>
      </div>
    </div>
  </form>
</div>
