import {Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {filter, from, map, mergeAll, mergeMap, Observable, of, switchMap, tap, toArray} from "rxjs";
import {GrpEncadrantsAttendance} from "../../models/grp-encadrants-attendance.model";
import {StrapiResponse} from "../../models/response.model";
import {GroupsService} from "../groups/groups.service";
import {Encadrant} from "../../models/encadrant";
import {EncadrantAttendance} from "../../models/encadrant-attendance.model";
import {GroupModel} from "../../models/group.model";
import {Replacement} from "../../models/replacement.model";

@Injectable({
  providedIn: 'root'
})
export class EncadrantsService {
  private readonly GRP_ENCADRANT_ATTENDANCE = (sessionId: number) => `/api/grp-encadrant-attendances?filters[session][id][$eq]=${sessionId}&populate[0]=session&populate[1]=group&populate[2]=encadrantAttendances&populate[3]=encadrantAttendances.encadrant&populate[4]=replacementAttendances&populate[5]=replacementAttendances.replacement`;
  private readonly GRP_ENCADRANT_ATTENDANCE_ID = (id: number) => `/api/grp-encadrant-attendances/${id}`;
  private readonly ENCADRANT_BY_GRP = (grpId: number) => `/api/encadrants?filters[group][id][$eq]=${grpId}`;
  private readonly ENCADRANT_ATTENANCES = `/api/encadrant-attendances`;
  private readonly ENCADRANT_ATTENANCES_ID = (id: number) => `/api/encadrant-attendances/${id}`;
  private readonly GRP_ENCADRANT_ATTENANCES = `/api/grp-encadrant-attendances?populate[0]=session&populate[1]=group&populate[2]=encadrantAttendances&populate[3]=encadrantAttendances.encadrant&populate[4]=replacementAttendances&populate[5]=replacementAttendances.replacement`;
  private readonly REPLACEMENTS = `/api/replacements`;
  private readonly REPLACEMENTS_ID = (id: number) => `/api/replacements/${id}`;

  constructor(private http: HttpClient,private groupService: GroupsService) { }

  fetchOrInitializeEncadrantsForSession(sessionId: number): Observable<GrpEncadrantsAttendance[]> {
    return this.fetchGrpEncadrantsAttendanceBySession(sessionId).pipe(
      switchMap(attendances => attendances.length > 0 ? of(attendances) : this.initializeEncadrantsForSession(sessionId)),
    );
  }

  private fetchGrpEncadrantsAttendanceBySession(sessionId: number): Observable<GrpEncadrantsAttendance[]> {
    return this.http.get<StrapiResponse<GrpEncadrantsAttendance[]>>(this.GRP_ENCADRANT_ATTENDANCE(sessionId)).pipe(
      map(respponse => respponse.data),
    );
  }

  private initializeEncadrantsForSession(sessionId: number): Observable<GrpEncadrantsAttendance[]> {
    return this.http.post<GrpEncadrantsAttendance[]>(`/api/initialize-encadrants/${sessionId}`, {});
  }

  fetchEncadrantsOfGroup(grpId: number): Observable<Encadrant[]> {
    return this.http.get<StrapiResponse<Encadrant[]>>(this.ENCADRANT_BY_GRP(grpId)).pipe(
      map(response => response.data)
    );
  }

  createEncadrantAttendance(body: Partial<EncadrantAttendance>): Observable<EncadrantAttendance> {
    return this.http.post<StrapiResponse<EncadrantAttendance>>(this.ENCADRANT_ATTENANCES, body).pipe(
      map(response => response.data)
    );
  }

  createGrpEncadrantAttendance(encadrantAttendances: GrpEncadrantsAttendance): Observable<GrpEncadrantsAttendance> {
    return this.http.post<StrapiResponse<GrpEncadrantsAttendance>>(this.GRP_ENCADRANT_ATTENANCES, encadrantAttendances).pipe(
      map(response => response.data)
    );
  }

  private mapToGrpEncadrantAttendance(sessionId: number, grpId: number, encadrantAttendances: EncadrantAttendance[]): GrpEncadrantsAttendance {
    return {
      session: {id: sessionId},
      group: {id: grpId},
      encadrantAttendances: encadrantAttendances,
      replacementAttendances: []
    };
  }

  private mapToEncadrantAttendance(encadrant: Encadrant): Partial<EncadrantAttendance> {
    return {
      present: false,
      late: false,
      isMain: encadrant.isMain,
      encadrant: {id: encadrant.id},
    };
  }

  saveEncadrantAttendance(id: number, encadrantAttendance: Partial<EncadrantAttendance>): Observable<EncadrantAttendance> {
    return this.http.put<StrapiResponse<EncadrantAttendance>>(this.ENCADRANT_ATTENANCES_ID(id), encadrantAttendance).pipe(
      map(response => response.data)
    );
  }

  fetchReplacements(): Observable<Replacement[]> {
    return this.http.get<StrapiResponse<Replacement[]>>(this.REPLACEMENTS).pipe(
      map(response => response.data)
    );
  }


  createReplacement(name: string): Observable<Replacement> {
    return this.http.post<StrapiResponse<Replacement>>(this.REPLACEMENTS, {name})
      .pipe(
        map(response => response.data)
      )
  }

  updateReplacement(id:number, name: string): Observable<Replacement> {
    return this.http.put<StrapiResponse<Replacement>>(this.REPLACEMENTS_ID(id), {name})
      .pipe(
        map(response => response.data)
      )
  }

  removeEncadrantFromGrpAttandance(groupAttendanceId: number, encadrantId: number): Observable<GrpEncadrantsAttendance> {
    const body = {
      data: {
        encadrantAttendances: {
          disconnect: [encadrantId]
        }
      }
    };

    return this.http.put<StrapiResponse<GrpEncadrantsAttendance>>(this.GRP_ENCADRANT_ATTENDANCE_ID(groupAttendanceId), body).pipe(
      map(response => response.data)
    )
  }

  addEncadranttoGrpAttandance(groupAttendanceId: number, encadrantId: number): Observable<GrpEncadrantsAttendance> {
    const body = {
      data: {
        encadrantAttendances: {
          connect: [encadrantId]
        }
      }
    };

    return this.http.put<StrapiResponse<GrpEncadrantsAttendance>>(this.GRP_ENCADRANT_ATTENDANCE_ID(groupAttendanceId), body).pipe(
      map(response => response.data)
    )
  }

  addReplacementtoGrpAttandance(groupAttendanceId: number, encadrantId: number): Observable<GrpEncadrantsAttendance> {
    const body = {
      data: {
        replacementAttendances: {
          connect: [encadrantId]
        }
      }
    };

    return this.http.put<StrapiResponse<GrpEncadrantsAttendance>>(this.GRP_ENCADRANT_ATTENDANCE_ID(groupAttendanceId), body).pipe(
      map(response => response.data)
    )
  }

  removeReplacementFromGrpAttandance(groupAttendanceId: number, encadrantId: number): Observable<GrpEncadrantsAttendance> {
    const body = {
      data: {
        replacementAttendances: {
          disconnect: [encadrantId]
        }
      }
    };

    return this.http.put<StrapiResponse<GrpEncadrantsAttendance>>(this.GRP_ENCADRANT_ATTENDANCE_ID(groupAttendanceId), body).pipe(
      map(response => response.data)
    )
  }
}
