import {Injectable, signal} from '@angular/core';
import {BehaviorSubject, filter, Observable, ReplaySubject, shareReplay, Subject, tap} from "rxjs";
import {UserInMemoryModel} from "../../models/user-in-memory.model";
import {PersonService} from "../person/person.service";

@Injectable({
  providedIn: 'root'
})
export class UserInMemoryService {

  readonly users$: Observable<UserInMemoryModel[]>;
  readonly hasUnsavedNames$: Observable<boolean>;

  private usersSubject = new BehaviorSubject<UserInMemoryModel[]>([]);
  private hasUnsavedNamesSubject = new Subject<boolean>();

  constructor(private personService: PersonService) {
    this.users$ = this.usersSubject.asObservable().pipe(shareReplay(1));
    this.hasUnsavedNames$ = this.hasUnsavedNamesSubject.asObservable();
  }

  addUsers(users: UserInMemoryModel[]): void {
    this.usersSubject.next(users);
    this.personService.getPersonsByGroup(users);
    this.hasUnsavedNamesSubject.next(false);
  }

  clear(): void {
    this.usersSubject.next([]);
  }
}
