import {Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserInMemoryService} from "../user-in-memory/user-in-memory.service";
import {BehaviorSubject, filter, map, mergeAll, mergeMap, Observable, tap, toArray} from "rxjs";
import {PersonModel} from "../../models/person.model";
import {StrapiResponse} from "../../models/response.model";
import {CookieService} from "ngx-cookie-service";
import {Constants} from "../../utils/constants";
import {UserInMemoryModel} from "../../models/user-in-memory.model";
import {sortBy} from "lodash-es";

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  private readonly PEOPLE = '/api/people';

  personByGroup = signal<PersonModel[]>([]);

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  getPersonsByGroup(users: UserInMemoryModel[]): void {
    const group = this.cookieService.get(Constants.GROUP_NAME);

    this.fetchPersonByGroup(group).pipe(
      mergeAll(),
      map((person: PersonModel) => {
        const userInfo = this.getUserInfo(users, person.identifier);
        return userInfo ? {...userInfo, ...person} : person;
      }),
      toArray(),
      map(persons => persons.sort((a, b) => a.name?.localeCompare(b?.name ?? '') ?? 0)),
      tap(persons => this.personByGroup.set(persons))
    )
      .subscribe();
  }

  fetchPersonByGroup(identifier: string): Observable<PersonModel[]> {
    return this.http.get<StrapiResponse<PersonModel[]>>(`${this.PEOPLE}?populate=group&filters[group][identifier][$eq]=${identifier}`).pipe(
      map(personResponse => personResponse.data),
    );
  }

  fetchNbPersonByGroup(identifier: string): Observable<number> {
    return this.http.get<StrapiResponse<PersonModel[]>>(`${this.PEOPLE}?populate=group&filters[group][identifier][$eq]=${identifier}`).pipe(
      map(personResponse => personResponse.meta.pagination.total),
    );
  }

  private getUserInfo(users: UserInMemoryModel[], id: string): UserInMemoryModel | undefined {
    return users.find((value => value.id === id));
  }
}
