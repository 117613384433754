import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {AttendanceBody, Attendances} from "../../models/attendances.model";
import {StrapiResponse} from "../../models/response.model";

@Injectable({
  providedIn: 'root'
})
export class AttendancesService {

  constructor(private http: HttpClient) { }

  saveAttendances(body: AttendanceBody, id?: number): Observable<StrapiResponse<Attendances>> {
    return id ? this.http.put<StrapiResponse<Attendances>>(`/api/attendances/${id}`, body) :
      this.http.post<StrapiResponse<Attendances>>('/api/attendances', body);
  }

  findAttendancesByGroupAndSession(groupId: number, sessionId: number): Observable<Attendances[]> {
    return this.http.get<StrapiResponse<Attendances[]>>(`/api/attendances?filters[group][id][$eq]=${groupId}&filters[session][id][$eq]=${sessionId}`)
      .pipe(
        map(response => response.data)
      )
  }
}
