import {Injectable, signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, map, Observable, ReplaySubject, shareReplay, tap} from "rxjs";
import {StrapiResponse} from "../../models/response.model";
import {Session} from "../../models/session.model";

@Injectable({
  providedIn: 'root'
})
export class SessionsService {
  private readonly SESSIONS = '/api/sessions?sort=date';
  private readonly URL_SESSION_ID = (id: number) => `/api/sessions/${id}?populate[attendances][populate][0]=person`;
  private sessionsSubject = new BehaviorSubject<Session[]>([]);
  private sessionSelectedSubject = new ReplaySubject<Session>(1);

  readonly sessions$: Observable<Session[]>;
  readonly sessionSelected$: Observable<Session>;

  constructor(private http: HttpClient) {
    this.sessions$ = this.sessionsSubject.asObservable().pipe(shareReplay(1));
    this.sessionSelected$ = this.sessionSelectedSubject.asObservable();
  }

  fetchSessions(): void {
    this.http.get<StrapiResponse<Session[]>>(this.SESSIONS).pipe(
      map(response => response.data ),
      tap(sessions => this.sessionsSubject.next(sessions)),
    ).subscribe();
  }

  fetchSessionById(id: number): void {
     this.http.get<StrapiResponse<Session>>(this.URL_SESSION_ID(id))
       .pipe(
         map(response => response.data ),
         tap(session => this.sessionSelectedSubject.next(session)),
       )
       .subscribe();
  }

}
