<div mat-dialog-content>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="mb-4">
          <div class="fw-bold border-bottom pb-2 mb-3">Change presence for {{data.encadrantAttendance?.encadrant?.name ?? data.encadrantAttendance.replacement?.name}}</div>
          <form [formGroup]="encadrantForm">
            <div class="row mb-3">
              <div class="col-5">
                Present:
              </div>
              <div class="col-6">
                <div class="form-check form-switch">
                  <input formControlName="present" type="checkbox" class="form-check-input form-check-input-success">
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-5">
                Late:
              </div>
              <div class="col-6">
                <div class="form-check form-switch">
                  <input formControlName="late" type="checkbox" class="form-check-input form-check-input-success">
                </div>
              </div>

            </div>
            <div class="row mb-3">
              <div class="col-5">
                Main:
              </div>
              <div class="col-6">
                <div class="form-check form-switch">
                  <input formControlName="main" type="checkbox" class="form-check-input form-check-input-success">
                </div>
              </div>

            </div>
            <div class="row mb-3">
              <div class="col-11 mb-1">
                Justification:
              </div>
              <div class="col-11">
                <textarea rows="3" cols="3" class="form-control" formControlName="justification" placeholder="Enter the justification here"></textarea>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-11">
                <mat-form-field class="w-100">
                  <mat-label>Select a group</mat-label>
                  <mat-select formControlName="groupAttendance">
                    <ng-container *ngFor="let groupAttendance of groupsAttendance$ | async">
                      <mat-option [value]="groupAttendance.id">{{groupAttendance.group.nom}}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link" [disabled]="isLoading()" (click)="onCancel()" >Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!encadrantForm.valid || isLoading()" (click)="onSubmit()">
          Confirm
          <i *ngIf="isLoading()" class="ph-spinner spinner m-1"></i>
        </button>
      </div>
    </div>
  </div>
</div>
