<div mat-dialog-content>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="mb-4">
          <div class="fw-bold border-bottom pb-2 mb-3">Add replacement</div>
          <form [formGroup]="replacementForm">
            <div class="row mb-3">
              <div class="col-lg-9">
                <mat-form-field class="example-full-width">
                  <mat-label>Number</mat-label>
                  <input type="text"
                         placeholder="Pick one"
                         aria-label="Number"
                         matInput
                         formControlName="replacement"
                         [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                    <ng-container *ngFor="let option of filteredReplacements()">
                      <mat-option [value]="option">{{option.name}}</mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </mat-form-field>
                <div *ngIf="replacementForm.controls['replacement']!.invalid && replacementForm.controls['replacement']!.touched" class="text-danger">
                  Name is required.
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-link" [disabled]="isLoading()" (click)="onCancel()" >Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!replacementForm.valid || isLoading()" (click)="onSubmit()">
          Confirm
          <i *ngIf="isLoading()" class="ph-spinner spinner m-1"></i>
        </button>
      </div>
    </div>
  </div>
</div>
