<div class="d-flex justify-content-between align-items-baseline pb-3">
  <button [disabled]="disablePrevButton()" (click)="goToPrevSession()" type="button" class="btn btn-outline-primary btn-icon rounded-pill">
    <i class="ph-arrow-left"></i>
  </button>

  <h5>{{selectedDate() | date: 'dd/MM/yyyy' }}</h5>

  <button [disabled]="disableNextButton()" (click)="goToNextSession()" type="button" class="btn btn-outline-primary btn-icon rounded-pill">
    <i class="ph-arrow-right"></i>
  </button>
</div>
