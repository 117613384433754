import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {UploadFileComponent} from "./components/upload-file/upload-file.component";
import {CommonModule} from "@angular/common";
import { AttendanceComponent } from './components/attendance/attendance.component';
import { LoginComponent } from './components/login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {JwtInterceptor} from "./services/jwt-interceptor/jwt-interceptor.service";
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { PasswordModalComponent } from './components/password-modal/password-modal.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import { GroupsComponent } from './components/groups/groups.component';
import { SessionNavigatorComponent } from './components/session-navigator/session-navigator.component';
import { EncadrantsAttendanceComponent } from './components/encadrants-attendance/encadrants-attendance.component';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import { EditEncadrantAttendanceModalComponent } from './components/edit-encadrant-attendance-modal/edit-encadrant-attendance-modal.component';
import {MatSelectModule} from "@angular/material/select";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { AddReplacementDialogComponent } from './components/add-replacement-dialog/add-replacement-dialog.component';
import {MatTabsModule} from "@angular/material/tabs";
import { TableAttendanceComponent } from './components/attendance/table-attendance/table-attendance.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import { UpdatePasswordComponent } from './components/update-password/update-password.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    UploadFileComponent,
    AttendanceComponent,
    LoginComponent,
    ResetPasswordComponent,
    PasswordModalComponent,
    GroupsComponent,
    SessionNavigatorComponent,
    EncadrantsAttendanceComponent,
    EditEncadrantAttendanceModalComponent,
    AddReplacementDialogComponent,
    TableAttendanceComponent,
    UpdatePasswordComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    MatSelectModule,
    MatTabsModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MatAutocompleteModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
