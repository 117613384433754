import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot, UrlTree,
} from '@angular/router';
import {AuthenticationService} from "../services/authentication/authentication.service";
import {CookieService} from "ngx-cookie-service";
import {Constants} from "../utils/constants";
import {map, Observable, of, tap} from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private router: Router, private cookieService: CookieService) {}

  canActivate(): Observable<boolean | UrlTree> {
    const userId = this.cookieService.get(Constants.USER_ID);
    if(!userId) {
      return of(this.router.createUrlTree(['login']))
    }

    return of(this.checkAdmin(this.authService.isAdmin()));
  }


  private checkAdmin(isAdmin: boolean): boolean | UrlTree {
    if (isAdmin) {
      return true;
    } else {
      return this.router.createUrlTree(['']);
    }
  }
}
