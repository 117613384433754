import {Injectable, signal} from '@angular/core';
import {PersonService} from "../person/person.service";
import {AttendancesService} from "../attendances/attendances.service";
import {GroupsService} from "../groups/groups.service";
import {forkJoin, map, mergeAll, mergeMap, Observable, switchMap, tap, toArray} from "rxjs";
import {GroupModel} from "../../models/group.model";
import {GroupAttendance, GroupsAttendance} from "../../models/group-attendance.model";

@Injectable({
  providedIn: 'root'
})
export class GroupAttendanceService {
  groupAttendance = signal<GroupsAttendance>({totalPersonPresent: 0, groupsAttendance: []});

  private totalPersonPresent = 0;

  constructor(private groupService: GroupsService, private personService: PersonService, private attendanceService: AttendancesService) { }

  fetchGroupAttendanceBySession(sessionId: number): void {
    this.totalPersonPresent = 0;
    this.groupService.fetchGroups()
      .pipe(
        mergeAll(),
        mergeMap((group: GroupModel) => this.calculatePresenceRate(group, sessionId)),
        toArray(),
        tap(groupsAttendance => this.groupAttendance.set({totalPersonPresent: this.totalPersonPresent, groupsAttendance})),
      ).subscribe();
  }

  private calculatePresenceRate(group: GroupModel, sessionId: number): Observable<GroupAttendance> {
    return forkJoin([
      this.personService.fetchNbPersonByGroup(group.identifier),
      this.attendanceService.findAttendancesByGroupAndSession(group.id, sessionId),
  ]).pipe(
      map(([totalPersons, attendances ]) => {
        const presentCount = attendances.filter((attendance) => attendance.present).length;
        const presenceRate = (presentCount / totalPersons) * 100;

        const lateCount = attendances.filter((attendance) => attendance.late).length;
        const lateRate = (lateCount / presentCount) * 100;
        this.totalPersonPresent += presentCount;

        return {
          groupId: group.id,
          groupName: group.nom,
          nbPersonPresent: presentCount,
          nbPerson: totalPersons,
          presenceRate:!isNaN(presenceRate) ? Math.round(presenceRate) : 0,
          nbPersonLate: lateCount,
          lateRate: !isNaN(lateRate) ? Math.round(lateRate) : 0
        };
      })
    );
  }}
