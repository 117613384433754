<div class="card">
  <div class="card-header">
    <h5 class="mb-0">Attendance</h5>
  </div>

  <div *ngIf="!!currentSession; else noSession" class="table-responsive">
    <table class="table">
      <thead>
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Present</th>
        <th>Late</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let person of personByGroup">
        <td>{{person?.name ?? person.identifier}}</td>
        <td>{{person?.lastName}}</td>
        <td>
          <div class="form-check form-switch mb-2">
            <input [checked]="attendanceMap.get(person.id)?.present ?? false"  (change)="handlePresent($event, person)" type="checkbox" class="form-check-input form-check-input-success">
          </div>
        </td>
        <td>
          <div class="form-check form-switch mb-2">
            <input [checked]="attendanceMap.get(person.id)?.late ?? false"  (change)="handleLate($event, person)" type="checkbox" class="form-check-input form-check-input-success">
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <ng-template #noSession>
    <div class="card-body">No session scheduled for today</div>
  </ng-template>

</div>
