import {Component, Input} from '@angular/core';
import {filter, Observable, tap} from "rxjs";
import {EncryptionService} from "../../services/encryption/encryption.service";
import {isUserInMemoryModel, UserInMemoryModel} from "../../models/user-in-memory.model";
import {UserInMemoryService} from "../../services/user-in-memory/user-in-memory.service";
import {MatDialog} from "@angular/material/dialog";
import {PasswordModalComponent} from "../password-modal/password-modal.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MatSnackBar} from "@angular/material/snack-bar";

@UntilDestroy()
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  @Input() isUpload = false;

  hasUnsavedNames$: Observable<boolean>;
  fileName = '';
  requiredFileType = '';
  selectedFile: File | null = null;

  constructor(private encryptionService: EncryptionService, private snackBar: MatSnackBar, private userInMemoryService: UserInMemoryService, private dialog: MatDialog) {
    this.hasUnsavedNames$ = userInMemoryService.hasUnsavedNames$;
  }

  onFileSelected(event: any): void {
    this.selectedFile = event.target.files[0];
    // réinitialiser le fichier séléctionné aprés la sélection
    if ( this.selectedFile ) {
      event.target.value = '';
    }
    this.openPasswordDialog();
  }

  openPasswordDialog(): void {
    const dialogRef = this.dialog.open(PasswordModalComponent, {
      width: '65vw'
    });

    dialogRef.afterClosed()
      .pipe(
        filter(Boolean),
        tap(password => this.uploadFile(this.selectedFile, password)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private uploadFile(file: File | null, password: string): void {
    if (file) {
      this.fileName = file.name;
      const reader = new FileReader();

      reader.onload = (e: any) => {
        try {
          const fileContent = e.target.result;
          const data = this.encryptionService.decryptData<UserInMemoryModel[]>(fileContent, password)
          if (!Array.isArray(data) || !data.every(isUserInMemoryModel)) {
            console.error("The data received is not in the correct format!");
            throw new Error('Invalid data type');
          }
          this.userInMemoryService.addUsers(data);
        } catch (error) {
          console.error("Error during the load of the file", error);
          this.showErrorToast('An error has occurred while loading the file');
        }
      };

      try {
        reader.readAsText(file);
      }catch(error) {
        console.error("Error during the read of the file", error);
        this.showErrorToast('An error has occurred while loading the file');
      }

    }
  }

  showErrorToast(message: string) {
    this.snackBar.open(message, 'close', {
      duration: 3000,
      panelClass: ['error-toast']
    });
  }
}
