import {ChangeDetectorRef, Component, computed, effect, OnInit, Signal, signal, untracked} from '@angular/core';
import {PersonService} from "../../services/person/person.service";
import {filter, map, mergeAll, Observable, switchMap, take, tap, toArray} from "rxjs";
import {PersonModel} from "../../models/person.model";
import {SessionsService} from "../../services/sessions/sessions.service";
import {AttendanceBody, Attendances} from "../../models/attendances.model";
import {AttendancesService} from "../../services/attendances/attendances.service";
import {Session} from "../../models/session.model";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {isEqual} from "lodash-es";
import {CookieService} from "ngx-cookie-service";
import {Constants} from "../../utils/constants";

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  personByGroup: Signal<PersonModel[]>;

  attendanceMap = signal(new Map<string,Attendances>(), {equal: isEqual});
  currentSession = signal<Session | null>(null);
  manageGroup = signal(false);

  personsPresent = computed(() => this.personByGroup().filter(person => this.attendanceMap()?.get(person.id)?.present))
  personsAbsent = computed(() =>this.personByGroup().filter(person => !this.attendanceMap()?.get(person.id)?.present))

  constructor(private personService: PersonService, private sessionService: SessionsService, private attendanceService: AttendancesService, private cookieService: CookieService) {
    this.personByGroup = personService.personByGroup.asReadonly();

    this.sessionService.sessionSelected$
      .pipe(
        tap(() => this.attendanceMap.set(new Map<string,Attendances>())),
        map(session => session?.attendances ?? []),
        mergeAll(),
        filter((attendance: Attendances) => !!attendance?.person?.id),
        tap((attendance: Attendances) => this.attendanceMap().set(attendance!.person!.id, attendance)),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.manageGroup.set(!!this.cookieService.get(Constants.GROUP_NAME));
  }

  handlePresent({event, person}) {
    const isChecked = (event.target as HTMLInputElement).checked;

    const newMap = new Map(this.attendanceMap());

    if(newMap.has(person.id)) {
      const currentAttendance = newMap.get(person.id)!;
      newMap.set(person.id, {
        ...currentAttendance,
        present: isChecked,
        late: isChecked ? currentAttendance.late : false
      });
    } else {
      newMap.set(person.id, { present: isChecked, late: false });
    }

    this.attendanceMap.set(newMap);
    this.saveAttendance(person);
  }

  handleLate({event, person}) {
    const isChecked = (event.target as HTMLInputElement).checked;

    const newMap = new Map(this.attendanceMap());

    if (newMap.has(person.id)) {
      const currentAttendance = newMap.get(person.id)!;
      newMap.set(person.id, {
        ...currentAttendance,
        late: isChecked,
        present: isChecked ? true : currentAttendance.present // Si en retard, alors aussi présent
      });
    } else {
      newMap.set(person.id, { present: isChecked, late: isChecked });
    }

    this.attendanceMap.set(newMap);
    this.saveAttendance(person);
  }

  handleCurrentSession(session: Session): void {
    this.currentSession.set(session);
  }

  private saveAttendance(person: PersonModel) {
    const body: AttendanceBody = {
      present: this.attendanceMap().get(person.id)!.present,
      late: this.attendanceMap().get(person.id)!.late,
      person: {
        id: person.id
      },
      session: {
        id: this.currentSession()!.id
      },
      group: {
        id: person.group.id
      }
    };

    this.attendanceService.saveAttendances(body, this.attendanceMap().get(person.id)?.id).pipe(
      map(response => response.data),
      tap((attendance) => this.attendanceMap().set(person.id, attendance))
    ).subscribe();
  }

}

