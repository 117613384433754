<div class="d-flex justify-content-center align-items-center">
  <form [formGroup]="loginForm" (ngSubmit)="login()">

    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <div class="d-inline-flex align-items-center justify-content-center mb-2 mt-2">
            <h1 class="edu-monitor d-sm-inline-block h-16px invert-dark ms-3">
              <span class="edu">Edu</span><span class="monitor">Monitor</span>
            </h1>
          </div>
          <h5 class="mb-0">Login to your account</h5>
          <span class="d-block text-muted">Enter your credentials below</span>
        </div>

        <div class="mb-3">
          <label class="form-label">Username</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input type="email" formControlName="email" id="email" required class="form-control">
            <div class="form-control-feedback-icon">
              <i class="ph-user-circle text-muted"></i>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Password</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input type="password" formControlName="password" id="password" required class="form-control">
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted"></i>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <button type="submit" class="btn btn-primary w-100">Sign in</button>
        </div>
      </div>
    </div>
  </form>
</div>
