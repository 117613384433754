<app-session-navigator [allowNavigationInFuture]="true" (currentSessionEmitter)="handleCurrentSession($event)"></app-session-navigator>
<div class="row">
  <div *ngFor="let grpEncadrant of grpEncadrantAttendance()">
    <div class="card">
      <div class="card-header">
        <h5 class="mb-0">{{grpEncadrant.group.nom}}</h5>
      </div>
      <div class="card-body p-0">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Present</th>
              <th>Late</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let encadrantAttendance of grpEncadrant?.encadrantAttendances">
              <td>{{encadrantAttendance.encadrant.name}}
                <i *ngIf="attendanceMap().get(encadrantAttendance.id!)?.isMain" class="ph-graduation-cap ph-1x"></i>
              </td>
              <td>
                <div class="form-check form-switch">
                  <input [checked]="attendanceMap().get(encadrantAttendance.id!)?.present ?? false"  (change)="handlePresent($event, encadrantAttendance)" type="checkbox" class="form-check-input form-check-input-success" >
                </div>
              </td>
              <td>
                <div class="form-check form-switch">
                  <input [checked]="attendanceMap().get(encadrantAttendance.id!)?.late ?? false"  (change)="handleLate($event, encadrantAttendance)" type="checkbox" class="form-check-input form-check-input-success">
                </div>
              </td>
              <td>
                <button mat-icon-button (click)="handleActionButton(attendanceMap().get(encadrantAttendance.id!)!, grpEncadrant,true)">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </td>
            </tr>
            <tr *ngFor="let replacementAttendance of grpEncadrant?.replacementAttendances">
              <td>{{replacementAttendance.replacement?.name}}*</td>
              <td>
                <div class="form-check form-switch">
                  <input [checked]="attendanceMap().get(replacementAttendance.id!)?.present ?? false"  (change)="handlePresent($event, replacementAttendance)" type="checkbox" class="form-check-input form-check-input-success" >
                </div>
              </td>
              <td>
                <div class="form-check form-switch">
                  <input [checked]="attendanceMap().get(replacementAttendance.id!)?.late ?? false"  (change)="handleLate($event, replacementAttendance)" type="checkbox" class="form-check-input form-check-input-success">
                </div>
              </td>
              <td>
                <button mat-icon-button (click)="handleActionButton(attendanceMap().get(replacementAttendance.id!)!, grpEncadrant, false)">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="card-footer d-flex justify-content-end gap-2 mt-1">
        <button class="btn btn-primary" (click)="addReplacement(grpEncadrant.id!)">Add replacement</button>
      </div>
    </div>
  </div>
</div>
