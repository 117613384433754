import {Component, signal} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {tap} from "rxjs";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {error} from "@angular/compiler-cli/src/transformers/util";

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent {
  form: FormGroup;

  errorMessage = signal('');

  constructor(private fb: FormBuilder, private authService: AuthenticationService, private router: Router) {
    this.form = fb.group({
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', [Validators.required]]
    }, {
      validators: MustMatch('newPassword', 'confirmPassword')
    })
  }


  updatePassword(): void {
    const currentPassword = this.form.get('password')?.value!;
    const newPassword = this.form.get('newPassword')?.value!;
    const passwordConfirmation = this.form.get('confirmPassword')?.value!;
    this.authService.updatePassword(currentPassword, newPassword, passwordConfirmation)
      .pipe(
        tap({
          next: () => this.router.navigate(['/']),
          error: (httpError: HttpErrorResponse) => this.errorMessage.set(httpError?.error?.error?.message ?? 'Something went wrong!')
        })
      )
      .subscribe();
  }
}

export const MustMatch = (controlName: string, matchingControlName: string) => {
  return (formGroup: FormGroup) => {
    const control = formGroup?.controls[controlName];
    const matchingControl = formGroup?.controls[matchingControlName];

    if (!control || !matchingControl) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });

    } else {
      matchingControl.setErrors(null);
    }

    return null;
  };
}
