<div class="navbar navbar-sm navbar-footer border-top">
  <div class="container-fluid">
    <span>&copy; BeDigit</span>

    <ul class="nav">
      <li class="nav-item ms-md-1">
        <a href="https://www.planningpme.fr/ajouter-raccourci-site-web-ecran-smartphone.htm" class="navbar-nav-link-icon text-primary bg-opacity-10 fw-semibold rounded" target="_blank">
          Install on device
        </a>
      </li>
    </ul>
  </div>
</div>
