import {Component, EventEmitter, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss']
})
export class PasswordModalComponent {
  passwordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PasswordModalComponent>
  ) {
    this.passwordForm = this.fb.group({
      password: ['', Validators.required]
    });
  }

  onSubmit(): void {
    if (this.passwordForm.valid) {
      this.dialogRef.close(this.passwordForm.value.password);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
