<div class="body-content">
  <app-header></app-header>

  <div class="page-content">
    <div class="content-wrapper">
      <div class="content-inner">
        <div class="content container">
          <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
      </div>
    </div>

  </div>


</div>
