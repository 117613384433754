import {Component, computed, Inject, OnInit, Signal, signal} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EncadrantsService} from "../../services/encadrants/encadrants.service";
import {filter, finalize, Observable, of, switchMap, tap} from "rxjs";
import {Replacement} from "../../models/replacement.model";
import {toSignal} from "@angular/core/rxjs-interop";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

export interface AddReplacementData {
  grpAttendanceId: number;
}

@UntilDestroy()
@Component({
  selector: 'app-add-replacement-dialog',
  templateUrl: './add-replacement-dialog.component.html',
  styleUrls: ['./add-replacement-dialog.component.scss']
})
export class AddReplacementDialogComponent implements OnInit {
  replacementForm: FormGroup;
  replacements: Signal<Replacement[]>;
  search = signal('');

  filteredReplacements = computed(() => {
    console.log('search: ', this.search())
    return this.replacements().filter(data => data.name?.toLowerCase().includes(this.search()?.toLowerCase() ?? ''))
  });

  isLoading = signal(false);

  constructor(private fb: FormBuilder,
              private encadrantService: EncadrantsService,
              @Inject(MAT_DIALOG_DATA) public data: AddReplacementData,
              public dialogRef: MatDialogRef<AddReplacementDialogComponent>) {
    this.replacementForm = this.buildReplacementForm();
    this.replacements = toSignal(this.encadrantService.fetchReplacements(), {initialValue: []});
  }

  ngOnInit(): void {
    this.replacementForm.get('replacement')?.valueChanges.pipe(
      filter(value => typeof value === 'string'),
      tap(value => this.search.set(value)),
      untilDestroyed(this)
    ).subscribe();
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    this.fetchOrCreateReplacement().pipe(
      tap({subscribe: () => this.isLoading.set(true)}),
      switchMap(replacement => this.encadrantService.createEncadrantAttendance({replacement})),
      switchMap( attendance => this.encadrantService.addReplacementtoGrpAttandance(this.data.grpAttendanceId!, attendance.id!)),
      tap(() => this.dialogRef.close(true)),
      finalize(() => this.isLoading.set(false))
    ).subscribe();
  }

  displayFn(replacement: Replacement): string {
    return replacement?.name ? replacement.name : '';
  }

  private fetchOrCreateReplacement(): Observable<Replacement> {
    const replacement = this.replacementForm.get('replacement')!.value;
    return (typeof replacement === 'string') ? this.encadrantService.createReplacement(replacement) : of(replacement as Replacement);
  }

  private buildReplacementForm() {
    return this.fb.group({
      replacement: [{id: 0, name: ''}, Validators.required]
    });
  }

}
