<ng-container *ngIf="manageGroup(); else noGroup">
  <app-session-navigator (currentSessionEmitter)="handleCurrentSession($event)"></app-session-navigator>
  <mat-tab-group>
    <mat-tab>
      <ng-container>
        <ng-template mat-tab-label>
          <div class="gap-2">
            Absent <div class="badge bg-warning">{{personsAbsent().length}}/{{personByGroup().length}}</div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <div class="pt-3">
            <app-table-attendance
              [personByGroup]="personsAbsent()"
              [attendanceMap]="attendanceMap()"
              [currentSession]="currentSession()"
              (presentEvent)="handlePresent($event)"
              (lateEvent)="handleLate($event)"
            ></app-table-attendance>
          </div>
        </ng-template>
      </ng-container>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="gap-2">
          Present <div class="badge bg-success">{{personsPresent().length}}/{{personByGroup().length}}</div>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <div class="pt-3">
          <app-table-attendance
            [personByGroup]="personsPresent()"
            [attendanceMap]="attendanceMap()"
            [currentSession]="currentSession()"
            (presentEvent)="handlePresent($event)"
            (lateEvent)="handleLate($event)"
          ></app-table-attendance>
        </div>

      </ng-template>
    </mat-tab>

  </mat-tab-group>
</ng-container>

<ng-template #noGroup>
  <div class="alert alert-warning" role="alert">
    It seems that you don't have any group yet
  </div>
</ng-template>
