import {Component, effect, OnInit, Signal, signal} from '@angular/core';
import {Session} from "../../models/session.model";
import {EncadrantsService} from "../../services/encadrants/encadrants.service";
import {GrpEncadrantsAttendance} from "../../models/grp-encadrants-attendance.model";
import {EncadrantAttendance} from "../../models/encadrant-attendance.model";
import {filter, map, mergeAll, Observable, switchMap, take, tap, toArray} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {
  EditEncadrantAttendanceModalComponent,
  EncadrantAttendanceData
} from "../edit-encadrant-attendance-modal/edit-encadrant-attendance-modal.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AddReplacementDialogComponent} from "../add-replacement-dialog/add-replacement-dialog.component";

@UntilDestroy()
@Component({
  selector: 'app-encadrants-attendance',
  templateUrl: './encadrants-attendance.component.html',
  styleUrls: ['./encadrants-attendance.component.scss']
})
export class EncadrantsAttendanceComponent implements OnInit {
  currentSession = signal<Session | null>(null);

  grpEncadrantAttendance = signal<GrpEncadrantsAttendance[]>([]);
  attendanceMap = signal(new Map<number,EncadrantAttendance>());

  constructor(private encadrantsService: EncadrantsService, private dialog: MatDialog ) {
    this.effectCurrentSession();
  }

  ngOnInit(): void {}

  handleCurrentSession(session: Session): void {
    this.currentSession.set(session);
  }

  private effectCurrentSession(): void {
    effect(() => {
      if (this.currentSession()) {
        this.fetchGrpAttendance().subscribe();
      }
    });
  }

  handlePresent(event: Event, encadrantAttendance: EncadrantAttendance) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const id = encadrantAttendance.id!;

    this.attendanceMap().get(id)!.present = isChecked;
    if(!isChecked) {
      this.attendanceMap().get(id)!.late = false;
    }

    this.saveAttendance(id, this.attendanceMap().get(id)!);
  }

  handleLate(event: Event, encadrantAttendance: EncadrantAttendance) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const id = encadrantAttendance.id!;

    this.attendanceMap().get(id)!.late = isChecked;
    if(isChecked) {
      this.attendanceMap().get(id)!.present = true;
    }

    this.saveAttendance(id, this.attendanceMap().get(id)!);
  }

  handleActionButton(encadrantAttendance: EncadrantAttendance, groupAttendance: GrpEncadrantsAttendance, isEncadrant: boolean): void {
    const data: EncadrantAttendanceData = {
      encadrantAttendance,
      groupAttendance,
      sessionId: this.currentSession()!.id,
      isEncadrant
    };
    this.dialog.open(EditEncadrantAttendanceModalComponent, {minWidth: '85vw', data})
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => this.fetchGrpAttendance()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  addReplacement(grpAttendanceId: number): void {
    this.dialog.open(AddReplacementDialogComponent, {data: {grpAttendanceId}})
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => this.fetchGrpAttendance()),
        untilDestroyed(this)
      )
      .subscribe();
  }


  private saveAttendance(id: number, body: Partial<EncadrantAttendance>) {
    this.encadrantsService.saveEncadrantAttendance(id, body).pipe(take(1)).subscribe();
  }

  private fetchGrpAttendance(): Observable<GrpEncadrantsAttendance[]> {
    return this.encadrantsService.fetchOrInitializeEncadrantsForSession(this.currentSession()!.id).pipe(
      tap(() => this.attendanceMap.set(new Map<number, EncadrantAttendance>())),
      tap((value) => this.grpEncadrantAttendance.set(this.sortByName(value))),
      mergeAll(),
      tap(grp => {
        [...grp.encadrantAttendances, ...grp?.replacementAttendances]
          .forEach(encadrantAttendance => this.attendanceMap().set(encadrantAttendance.id!, encadrantAttendance))
      }),
      toArray()
    );
  }

  private sortByName(grpAttendances: GrpEncadrantsAttendance[]): GrpEncadrantsAttendance[] {
    return grpAttendances.sort((a, b) => a.group!.nom!.localeCompare(b.group!.nom!))
  }
}
