import {Component, effect, OnInit, ViewEncapsulation} from '@angular/core';
import {AuthenticationService} from "./services/authentication/authentication.service";
import {Constants} from "./utils/constants";
import {CookieService} from "ngx-cookie-service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Router} from "@angular/router";

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'edu-monitor-angular';

  constructor(private authService: AuthenticationService, private cookieService: CookieService, private router: Router) {
    effect(() => {
     if(this.authService.user()?.forcePasswordReset) {
       this.router.navigate(['update-password']);
     }
    });
  }

  ngOnInit(): void {
    const userId = +this.cookieService.get(Constants.USER_ID);
    const token = this.cookieService.get(Constants.TOKEN_NAME);

    if(userId && token) {
      this.authService.fetchConnectedUser();
      this.authService.fetchEncadrantByUserId(userId)
        .pipe(
          untilDestroyed(this)
        )
        .subscribe();
    }


  }


}
